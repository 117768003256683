/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        function setHeight() {
          windowHeight = $(window).innerHeight();
          $('.flexnav').css('height', windowHeight + 15);
        }
        setHeight();
        $(window).resize(function() {
          setHeight();
        });

        // Flexnav stuff
        $(".nav-section ul.flex-filter").flexNav({
          'buttonSelector' : "#nav-section-button",
          'animationSpeed' : 'fast',
          'hover' : true,
          'hoverIntent': true, // use with hoverIntent plugin
          'hoverIntentTimeout': 150 // hoverIntent default timeout
        });

        $(".nav-primary ul.flexnav").flexNav({
          'buttonSelector' : "#nav-primary-button",
          'animationSpeed' : 'fast',
          'hover' : true,
          'hoverIntent': true, // use with hoverIntent plugin
          'hoverIntentTimeout': 150 // hoverIntent default timeout
        });

        $( "#nav-primary-button" ).click(function() {
          $(".section-menu").toggleClass( "fade" );
        });

        $('.video-player').click(function() {
          var videoPlayer = $(this);
      		videoPlayer.children(".video-thumb").fadeOut('slow', function() {
      			videoPlayer.children(".video").css("display","block");
      		});
          videoPlayer.attr("src", function() {
           videoPlayer.children(".video").attr("src", videoPlayer.children(".video").attr("src") + "?rel=0&autoplay=1");
          });
        });

        $(function() {
         var options = window.sdc395MapOptions;
         var map = new google.maps.Map(document.getElementById("map-canvas"),
         {
           zoom: options.zoom,
           center: new google.maps.LatLng(options.latitude, options.longitude),
           mapTypeId: google.maps.MapTypeId.ROADMAP
         });
         $.ajax({
          url: '/wp-json/sdc395/locations',
          method: 'get',
          dataType: 'json',
          }).done(function (data) {
              data.forEach(function (r) {
                  new google.maps.Marker({
                      position: new google.maps.LatLng(r.latitude, r.longitude),
                      map: map
                  });
              });
          });
        });
        // Open #overLay onLoad
        $('#overLay').modal('show');
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
